import dynamic from 'next/dynamic'
import { useCallback, useEffect, useState } from 'react'
// Example of valid Input  '2022-11-10T00:00-04:00'
const Text = dynamic(import('@csc/dls/Text'))

export const isValidCountDownDate = (date = '') => (
  !Number.isNaN(Date.parse(date))
  && new Date(date).getTime() > (new Date()).getTime()
)

function parseDate(date: string) {
  const parsed = Date.parse(date)
  if (isValidCountDownDate(date)) {
    return parsed
  }
  return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '))
}

const getTimeDistanceToUpdate = (endDate: number, present = (new Date()).getTime()) => {
  const distance = endDate - present
  const seconds = (Math.floor((distance % (1000 * 60)) / 1000))
  const minutes = (Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)))
  const hours = (Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))
  const days = (Math.floor((distance / (1000 * 60 * 60 * 24))))
  return {
    seconds,
    minutes,
    hours,
    days,
  }
}

const CountdownTimer: React.FC<{
  countdownTargetString: string
}> = ({ countdownTargetString }) => {
  const endDate = parseDate(countdownTargetString)
  const [seconds, setSeconds] = useState(getTimeDistanceToUpdate(endDate).seconds)
  const [minutes, setMinutes] = useState(getTimeDistanceToUpdate(endDate).minutes)
  const [hours, setHours] = useState(getTimeDistanceToUpdate(endDate).hours)
  const [days, setDays] = useState(getTimeDistanceToUpdate(endDate).days)
  const [shouldDisplay, setShouldDisplay] = useState(endDate > ((new Date()).getTime()))

  const updateClock = useCallback(() => {
    const present = new Date().getTime()
    if (endDate < present) {
      setShouldDisplay(false)
    }
    const timeDistance = getTimeDistanceToUpdate(endDate, present)
    setSeconds(timeDistance.seconds)
    setMinutes(timeDistance.minutes)
    setHours(timeDistance.hours)
    setDays(timeDistance.days)
    if (!shouldDisplay) {
      setShouldDisplay(true)
    }
  }, [endDate, shouldDisplay])

  useEffect(updateClock)

  const getIntervalCleaner = (intervalGetter: NodeJS.Timeout) => () => clearInterval(intervalGetter)
  const intervalSetter = useCallback((t = 1000) => setInterval(updateClock, t), [updateClock])
  useEffect(() => getIntervalCleaner(intervalSetter()), [intervalSetter])

  const getClockBadge = (value: number, title: string, hasMR = true) => (
    <div className={`flex flex-col my-1 ml-0 w-8.5 bg-white py-0.5 px-1 ${hasMR ? 'mr-1' : ''}`}>
      <Text textId="countdown-clock-value">
        {value}
      </Text>
      <Text bold size="xs" className="text-black uppercase">
        {title}
      </Text>
    </div>
  )
  return (
    <div data-testid="countdownTimer" style={{ display: shouldDisplay ? 'inherit' : 'none' }}>
      {!!days && getClockBadge(days, 'days')}
      {getClockBadge(hours, 'hrs')}
      {getClockBadge(minutes, 'mins')}
      {getClockBadge(seconds, 'secs', false)}
    </div>
  )
}

export default CountdownTimer
